body {
  margin: 0 !important;
  padding: 0 !important;
  overflow: auto !important;
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol" !important;
  -webkit-font-smoothing: auto;
}

.confirm-button-class {
  background-color: #fdad3a;
  color: #000;
  outline: none !important;
  padding: 10px 2rem;
  font-weight: bolder;
  font-size: 18px;
  cursor: pointer;
  border: none !important;
}

.swal2-container {
  z-index: 99999 !important;
}

.content-class {
  font-weight: bolder !important;
  font-size: 20px !important;
}

.color-change {
  color: #fdad3a !important;
}
